/**
 *  加载js代码
 *  @param {string} src 可加载的一段url.
 *  @param {string} callback 回调函数（可选）.
 *  @returns {promise}
 */
const loadPromises = {};
export function loadScript(src, opts = {}) {
    if (loadPromises[src]) {
        return loadPromises[src];
    }
    const promise = (loadPromises[src] = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = opts.type || 'text/javascript';
        script.charset = opts.charset || 'utf8';
        script.async = 'async' in opts ? !!opts.async : true;
        script.src = src;
        if (opts.attrs) {
            for (var attr in opts.attrs) {
                script.setAttribute(attr, opts.attrs[attr]);
            }
        }
        let loaded = false;
        script.onload = script.onreadystatechange = function () {
            if ((script.readyState &&
                script.readyState !== 'complete' &&
                script.readyState !== 'loaded') ||
                loaded) {
                return false;
            }
            script.onload = script.onreadystatechange = null;
            loaded = true;
            resolve();
        };
        script.onerror = function () {
            script.onload = script.onreadystatechange = null;
            reject();
        };
        const head = document.getElementsByTagName('head')[0];
        head.insertBefore(script, head.firstChild);
    }));
    return promise;
}
/**
 * Compare versions (only number)
 * @param v1
 * @param v2
 * @returns {
 *  1: v1 > v2
 *  0: v1 == v2
 *  -1: v1 < v2
 *  }
 */
export function compareVersions(v1, v2) {
    var v1Parts = v1.split('.');
    var v2Parts = v2.split('.');
    const length = Math.max(v1Parts.length, v2Parts.length);
    for (var i = 0; i < length; i++) {
        var a = parseInt(v2Parts[i], 10) || 0;
        var b = parseInt(v1Parts[i], 10) || 0;
        if (a > b) {
            return -1;
        }
        if (a < b) {
            return 1;
        }
    }
    return 0;
}
