import './index.scss';
import { getLibOrigin } from '@bbtfe/utils';
/**
 * 通用下载条
 * @param {*} options
 */
export function DownloadBar(
  options = {
    container: document.body,
    className: '',
    logo: `${getLibOrigin()}/h5_fe_lib/release/asset/wla/logo.png`,
    title: '宝宝树孕育',
    desc: '孕育养娃神器',
    btnText: '立即下载',
    onDownloadBtnClick: function () {
      return false;
    },
    onCloseBtnClick: function () {
      return false;
    }
  }
) {
  let wrapper = document.createElement('div');
  wrapper.className = `bbt-wla-bar${
    options.className ? ` ${options.className}` : ''
  }`;
  wrapper.innerHTML = [
    `<div class="close-btn"></div>`,
    `<img class="logo" src="${
      options.logo || `${getLibOrigin()}/h5_fe_lib/release/asset/wla/logo.png`
    }" />`,
    '<div class="info">',
    `<div class="title">${options.title || '宝宝树孕育'}</div>`,
    `<div class="desc">${options.desc || '孕育养娃神器'}</div>`,
    '</div>',
    `<div class="download-btn">${options.btnText || '立即下载'}</div>`
  ].join('');
  let downloadBtn = wrapper.querySelector('.download-btn');
  let closeBtn = wrapper.querySelector('.close-btn');
  const destroy = function () {
    wrapper.remove();
    wrapper = null;
    closeBtn = null;
    downloadBtn = null;
  };
  downloadBtn.addEventListener('click', function () {
    if (options.onDownloadBtnClick && options.onDownloadBtnClick() === true) {
      return;
    }
    options.__executor && options.__executor();
  });
  closeBtn.addEventListener('click', function () {
    if (options.onCloseBtnClick && options.onCloseBtnClick() === true) {
      return;
    }
    destroy();
  });
  const container = options.container || document.body;
  container.append(wrapper);
  return destroy;
}
