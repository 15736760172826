import { isApp } from '@bbtfe/utils';
import { ua, detector } from './detector';
import {
  locationCall,
  deepMerge,
  defaultOptions,
  isIos,
  isAndroid,
  inWeixinMiniApp,
  inWeixin,
  openChannel,
  openStatus,
  useGuideMethod,
  getSEO,
  getUrlInfo,
} from './common';
import { getQuery, addOrUpdateQuery } from '@bbtfe/utils';
import { lazyWTA, lazyTracking, lazyWX } from './util/bbtlazy';
import { DownloadBar } from './ui';

(function () {
  lazyWTA();

  lazyTracking();

  if (inWeixin || inWeixinMiniApp) {
    lazyWX();
  }
})();

class WebLaunchApp {
  constructor(options = {}) {
    this.setOptions(options);
    this._log(ua);
    this._log(detector);
    if (this.options.auto) {
      this.open(this.options.callback);
    };
    // 流量归因曝光埋点
    if(!isApp()&&!options.noExposure){
      window.bbtTracking.send({
        bpi: '49897',
        pt: 'AS_DP',
        pi: 'AS_DP',
        an: '1',
        be: `tcodeurl=${window.encodeURIComponent(
          getUrlInfo().url
        )}$tcode=${getQuery('tcode')}$out_channel=${getSEO()}$click_url=${window.encodeURIComponent(options.scheme)}$page_id=${getUrlInfo().pid}`
      });
    }
  }

  setOptions(options = {}) {
    this.debug = options.debug || false;
    this.options = deepMerge(
      deepMerge({}, this.options || defaultOptions),
      options
    );
    this.options.useGuideMethod = useGuideMethod(this.options);
    this.openMethod = this._getOpenMethod();
    this._log(this.options);
  }

  _log(...args) {
    if (this.debug) {
      console.log('WebLaunchApp', ...args);
    }
  }
  /**
   * select open method according to the environment and config
   */
  _getOpenMethod() {
    let { guide, redirect, link, scheme, unknown } = openChannel;
    let { useGuideMethod, useRedirectMethod, useUniversalLink, useAppLink } =
      this.options;
    if (useGuideMethod) {
      this._log('guide');
      this.openType = 'guide';
      return guide;
    }
    if (useRedirectMethod) {
      this._log('redirect');
      this.openType = 'redirect';
      return redirect;
    }
    if (useUniversalLink || useAppLink) {
      this._log('link');
      this.openType = 'link';
      return link;
    }
    if (isIos || isAndroid) {
      this._log('scheme');
      this.openType = 'scheme';
      return scheme;
    }
    this._log('unknown');
    this.openType = 'unknown';
    return unknown;
  }

  open(config = {}, callback) {
    if (this.locker) return;
    this.locker = true;
    try {
      this.openScheme = config.scheme || this.options.scheme;
      this.redirectPage = config.redirectPage || this.options.redirectPage;
      this.redirectPageParam =
        config.redirectPageParam || this.options.redirectPageParam;
      this.callback = callback;
      const tcode =
        config.tcode || this.options.tcode || getQuery('tcode') || undefined;
      if (tcode) {
        this.openScheme = addOrUpdateQuery('tcode', tcode, this.openScheme);
      }
      const unionid =
        config.unionid ||
        this.options.unionid ||
        getQuery('unionid') ||
        undefined;
      if (unionid) {
        this.openScheme = addOrUpdateQuery('unionid', unionid, this.openScheme);
      }
      // openConifg.clipboardTxt && copy(openConifg.clipboardTxt);
      if (this.openMethod.preOpen) {
        this.openMethod.preOpen.call(this);
      }
      // 流量归因点击埋点
      window.bbtTracking.send({
        bpi: '49898',
        pt: 'AS_DP',
        pi: 'AS_DP',
        an: '2',
        ii: '01',
        be: `tcodeurl=${window.encodeURIComponent(
          getUrlInfo().url
        )}$tcode=${getQuery('tcode')}$out_channel=${getSEO()}$click_url=${window.encodeURIComponent(this.options.scheme)}$page_id=${getUrlInfo().pid}`
      });
      if (this.openType == 'link' || this.openType == 'scheme') {
        window.bbtTracking.send({
          bpi: '44633',
          pt: 'SH_DP',
          pi: 'SH_DP',
          an: '2',
          ii: '02',
          be: `tcodeurl=${window.encodeURIComponent(
            window.location.href
          )}$click_url=${window.encodeURIComponent(this.openScheme)}`
        });
      }
      if (
        (this.options.useWta || config.useWta) &&
        window.WTA &&
        (this.options.wta || config.wta)
      ) {
        const data = {
          scheme: this.openScheme,
          ...this.options.wta,
          ...config.wta
        };
        this._log('wta', data);
        WTA.init(data);
      }
      this.locker = false;
      this.openMethod.open.call(this);
    } catch (error) {
      this.locker = false;
      this._log('launch error', error);
      locationCall(this.options.landPage, this.options.noLandpage);
    }
  }

  download() {
    const pkgs = this.options.pkgs;
    if (inWeixin) {
      // 微信应用宝
      this._log('download yyb', pkgs.yyb);
      locationCall(pkgs.yyb, this.options.noLandpage);
    } else if (pkgs.channel && pkgs.channel.url) {
      this._log('pkgs.channel', pkgs.channel.url);
      locationCall(pkgs.channel.url, this.options.noLandpage);
    } else if (isAndroid) {
      // 安卓包下载地址
      this._log('download android', pkgs.android);
      locationCall(pkgs.android, this.options.noLandpage);
    } else if (isIos) {
      // ios app store
      this._log('download ios', pkgs.ios);
      locationCall(pkgs.ios, this.options.noLandpage);
    } else {
      // 兜底页
      this._log('download landPage', this.options.landPage);
      locationCall(this.options.landPage, this.options.noLandpage);
    }
  }

  _callend(status) {
    this._log('_callend', status);
    window.clearTimeout(this.timer);
    const backResult =
      this.callback &&
      this.callback(status, detector, {
        openUrl: this.openUrl,
        scheme: this.openScheme
      });
    // 调起失败处理
    if (status != openStatus.SUCCESS) {
      switch (backResult) {
        case 1:
          // do nothing
          break;
        case 2:
          locationCall(this.options.landPage, this.options.noLandpage);
          break;
        case 3:
          // 指定参数后续无超时逻辑
          openChannel.store.open.call(this, true);
          break;
        default:
          this.download();
          break;
      }
    } else {
      if (window.bbtTracking) {
        window.bbtTracking.send({
          bpi: '39594',
          pt: 'SH_DP',
          pi: 'SH_DP',
          an: '2',
          ii: '01',
          be: `tcodeurl=${window.encodeURIComponent(
            window.location.href
          )}$click_url=${window.encodeURIComponent(this.openScheme)}`
        });
      }
    }
  }

  _setTimeEvent() {
    const self = this;
    let haveChange = false;
    let property = 'hidden',
      eventName = 'visibilitychange';
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      property = 'hidden';
      eventName = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      property = 'msHidden';
      eventName = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      property = 'webkitHidden';
      eventName = 'webkitvisibilitychange';
    }

    const pageChange = function (e) {
      haveChange = true;
      if (
        document[property] ||
        e.hidden ||
        document.visibilityState == 'hidden'
      ) {
        self._callend(openStatus.SUCCESS);
      } else {
        self._callend(openStatus.UNKNOW);
      }
      // document.removeEventListener('pagehide', pageChange);
      document.removeEventListener(eventName, pageChange);
    };
    // window.addEventListener('pagehide', pageChange, false);
    document.addEventListener(eventName, pageChange, false);

    this.timer = window.setTimeout(function () {
      if (haveChange) {
        return;
      }
      // document.removeEventListener('pagehide', pageChange);
      document.removeEventListener(eventName, pageChange);

      // document.removeEventListener
      if (!document.hidden && !haveChange) {
        self._callend(openStatus.FAILED);
      } else {
        self._callend(openStatus.UNKNOW);
      }
      haveChange = true;
    }, this.options.timeout);
  }

  render(type, options) {
    const ui = WebLaunchApp.ui[type];
    if (ui) {
      if (!this.__destroy) {
        this.__destroy = {};
      }
      if (this.__destroy[type]) {
        this.__destroy[type].call(this);
      }
      const destroy = ui({
        ...options,
        __executor: () => {
          this.open();
        }
      });
      this.__destroy[type] = destroy;
    }
  }

  destroy() {
    this._log('launch destroy');
    if (this.__destroy) {
      Object.values(this.__destroy).forEach(destroy => {
        destroy.call(this);
      });
    }
  }
}

WebLaunchApp.detector = detector;
WebLaunchApp.ua = ua;
WebLaunchApp.ui = {
  DownloadBar
};

export default WebLaunchApp;
