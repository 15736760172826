import { ua, detector, chromeDetector } from './detector';
import { base64encode } from './base64';
export const isIos = detector.os.name === 'ios';
export const isAndroid = detector.os.name === 'android';
export const enableULink = isIos && detector.os.version >= 9; // 大于ios9可启用universalLink
export const enableApplink = false; // isAndroid && detector.os.version >= 6; // 大于安卓6可以启用applink目前app未实现
export const inWeixin = detector.browser.name === 'micromessenger';
export const inQQ = detector.browser.name === 'qq';
export const inWeibo = detector.browser.name === 'weibo';
export const inBaidu = detector.browser.name === 'baidu';
export const inDingtalk = detector.browser.name === 'dingtalk';
export const inWeixinMiniApp =
  detector.browser.name === 'micromessenger-miniprogram';
const COOKIE_VALUE="zw_llgy_text"

/**
 * 宿主环境是否支持link
 */
export function supportLink() {
  let supportLink = false;
  if (enableApplink) {
    switch (detector.browser.name) {
      case 'chrome':
      case 'samsung':
      case 'zhousi':
        supportLink = true;
        break;
      default:
        supportLink = false;
        break;
    }
  }
  if (enableULink) {
    switch (detector.browser.name) {
      case 'uc':
      case 'qq':
        supportLink = false;
        break;
      default:
        supportLink = true;
        break;
    }
  }
  return supportLink;
}

/**
 * iframe call
 * @param url
 */
export function iframeCall(url) {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', url);
  iframe.setAttribute('style', 'display:none');
  document.body.appendChild(iframe);
  setTimeout(function () {
    document.body.removeChild(iframe);
  }, 200);
}

/**
 * location call
 * @param url
 */
export function locationCall(url, noLandpage = false) {
  if (!noLandpage) {
    (top.location || location).href = url;
  } else {
    if (url.indexOf('popen.babytree.com') == -1) {
      (top.location || location).href = url;
    }
  }
}

/**
 * merge object
 */
export function deepMerge(firstObj, secondObj) {
  for (var key in secondObj) {
    firstObj[key] =
      firstObj[key] && firstObj[key].toString() === '[object Object]'
        ? deepMerge(firstObj[key], secondObj[key])
        : (firstObj[key] = secondObj[key]);
  }
  return firstObj;
}

export function useGuideMethod(options = {}) {
  if (inWeibo || inQQ) {
    return true;
  }
  if (isAndroid) {
    return inWeixin || inDingtalk;
  }
  if (options.auto && isIos) {
    return detector.browser.name !== 'safari';
  }
  return false;
}

export function useRedirectMethod(options = {}) {
  if (inWeixinMiniApp) {
    return true;
  }
  return false;
}

export const defaultOptions = {
  auto: false,
  scheme: 'bbtrp://com.babytree.pregnancy/home/homepage',
  pkgs: {
    yyb: 'http://a.app.qq.com/o/simple.jsp?pkgname=com.babytree.apps.pregnancy&ckey=CK1351100144579',
    ios: 'http://itunes.apple.com/cn/app/kuai-le-yun-qi-40zhou-quan/id523063187?mt=8',
    android:
      'http://pic11.babytreeimg.com/foto3/common_photo/original/2018/1129/a9be56a347822828.jpg'
    // channel: {
    //   refcode: 'm_y_tywnl_20190711',
    //   url: '//r.babytree.com/8iSVC2b'
    // }
  },
  // ios app ulink
  useUniversalLink: supportLink(),
  // 安卓 app link
  useAppLink: supportLink(),
  // 受限引导
  useGuideMethod: useGuideMethod(),
  // 重定向引导：微信小程序重定向到小程序中间页
  useRedirectMethod: useRedirectMethod(),
  // 超时下载, <0表示不使用超时下载
  timeout: 2000,
  // 兜底页面
  landPage: 'https://popen.babytree.com/pregnancy/index.html',
  // noLandpage为true时不进兜底页
  noLandpage: false,
  // wta配置
  wta: {
    appid: '11',
    param: {},
    bid: 'pregnancy_20200401',
    tid: ''
  },
  useWta: true,
  callback: (status, detector, data) => {},
  onGuideOpen: (context2WLA, detector) => {
    const div = document.createElement('div');
    div.className = 'wla-guide-div';
    div.style.position = 'fixed';
    div.style.top = '0';
    div.style.left = '0';
    div.style.zIndex = '99999';
    div.style.width = '100vw';
    div.style.height = '100vh';
    div.style.textAlign = 'center';
    div.style.backgroundColor = 'rgba(0,0,0,0.7)';
    div.style.padding = '20px 10px';
    div.style.boxSizing = 'border-box';
    const img = document.createElement('img');
    img.style.width = '100%';
    // ios android
    img.src =
      detector.os.name == 'ios'
        ? '//static02.babytreeimg.com/img/preg-fe/caneat/common/ios-cover.png'
        : '//static02.babytreeimg.com/img/preg-fe/caneat/common/android-cover.png';
    div.appendChild(img);
    document.body.appendChild(div);
    div.onclick = function () {
      div.remove();
    };
  },
  onLinkOpen: null,
  onSchemeOpen: null,
  // onLinkOpen: (context2WLA) => false,
  // onSchemeOpen: (context2WLA) => false
  redirectPage: '/pages/h5Package/goContact/index',
  redirectPageParam: null,
  onRedirectOpen: null,
  redirectOpen: null
};

let locker = false;

export const openChannel = {
  // 目前link实现只有ios ulink
  link: {
    preOpen: function () {
      if (this.options.onLinkOpen) {
        this.openUrl = this.options.onLinkOpen(this, detector);
        return;
      }
      const { pkgs } = this.options;
      const { openScheme } = this;
      let refcode = '',
        downloadUrl = '';
      if (pkgs.channel) {
        refcode = pkgs.channel.refcode || '';
        downloadUrl = pkgs.channel.url || '';
      }
      if (!downloadUrl) {
        if (isIos) {
          downloadUrl = pkgs.ios;
        } else if (isAndroid) {
          downloadUrl = pkgs.android;
        }
      }
      this.openUrl = `https://popen.babytree.com/pregnancy/index.html?middlePage=1&loadurl=${window.encodeURIComponent(
        openScheme
      )}&refcode=${refcode}&downloadUrl=${
        downloadUrl ? window.encodeURIComponent(downloadUrl) : ''
      }`;
    },
    open: function () {
      if (this.options.timeout) {
        this._setTimeEvent();
      }
      this._log('link open', this.openUrl);
      locationCall(this.openUrl, this.options.noLandpage);
    }
  },
  scheme: {
    preOpen: function () {
      if (this.options.onSchemeOpen) {
        this.openUrl = this.options.onSchemeOpen(this, detector);
        return;
      }
      const { openScheme } = this;
      if (/^http[s]?/.test(openScheme)) {
        this.openUrl = `babytree://webview?url=${base64encode(openScheme)}`;
      } else {
        this.openUrl = openScheme;
      }
    },
    open: function () {
      if (this.options.timeout) {
        this._setTimeEvent();
      }
      this._log('scheme open', this.openUrl);
      if (
        detector.browser.name == 'safari' &&
        detector.os.version >= 9 &&
        isIos
      ) {
        locationCall(this.openUrl, this.options.noLandpage);
      } else if (
        isAndroid &&
        chromeDetector.browser.name == 'chrome' &&
        chromeDetector.browser.version > 55
      ) {
        locationCall(this.openUrl, this.options.noLandpage);
      } else {
        iframeCall(this.openUrl);
      }
    }
  },
  redirect: {
    preOpen: function () {
      if (this.options.onRedirectOpen) {
        this.openUrl = this.options.onRedirectOpen(this, detector);
        return;
      }
      const { openScheme, redirectPage, redirectPageParam } = this;
      let shareOptions = {
        title: document.title,
        content: '宝宝树孕育',
        imgUrl:
          'https://pic01.babytreeimg.com/img/wp/ic/lg_pregnancy_big_2020.png'
      };
      let redirect_title = shareOptions.title;
      let redirect_content = shareOptions.content;
      let redirect_img = shareOptions.imgUrl;
      const meta = document.getElementById('bbt:share');
      if (meta) {
        try {
          shareOptions = JSON.parse(meta.content);
          redirect_title = shareOptions.title;
          redirect_content = shareOptions.content;
          redirect_img = shareOptions.imgUrl;
        } catch (e) {}
      }
      let extraQueryString = '';
      if (redirectPageParam) {
        for (let key in redirectPageParam) {
          let value = redirectPageParam[key];
          extraQueryString = `&${key}=${value}${extraQueryString}`;
        }
      }
      this.openUrl = `${redirectPage}?redirect_url=${window.encodeURIComponent(
        openScheme
      )}&redirect_title=${window.encodeURIComponent(
        redirect_title
      )}&redirect_content=${window.encodeURIComponent(
        redirect_content
      )}&redirect_img=${window.encodeURIComponent(redirect_img)}${
        extraQueryString ? extraQueryString : ''
      }`;
    },
    open: function () {
      if (locker == true) return;
      locker = true;
      this._log('redirect open');
      if (this.options.redirectOpen) {
        this.options.redirectOpen(this.openUrl);
        locker = false;
        return;
      }
      if (!window.WeixinJSBridge || !window.WeixinJSBridge.invoke) {
        document.addEventListener(
          'WeixinJSBridgeReady',
          () => {
            this._log('redirect open', this.openUrl);
            wx.miniProgram.navigateTo({ url: this.openUrl });
            locker = false;
          },
          false
        );
      } else {
        this._log('redirect open', this.openUrl);
        wx.miniProgram.navigateTo({ url: this.openUrl });
        locker = false;
      }
    }
  },
  guide: {
    open: function () {
      this._log('guide open');
      let func = this.options.onGuideOpen;
      func && func(this, detector);
    }
  },
  store: {
    open: function () {
      // 超时处理
      if (this.options.timeout) {
        this._setTimeEvent();
      }
      const { pkgs } = this.options;
      if (inWeixin || inQQ) {
        this._log('store open yyb', pkgs.yyb);
        locationCall(pkgs.yyb, this.options.noLandpage);
      } else if (pkgs.channel) {
        locationCall(pkgs.channel.url, this.options.noLandpage);
      } else if (isIos) {
        this._log('store open ios', pkgs.ios);
        locationCall(pkgs.ios, this.options.noLandpage);
      } else if (isAndroid) {
        this._log('store open android', pkgs.android);
        iframeCall(pkgs.android);
      }
    }
  },
  unknown: {
    open: function () {
      this._log('unknown open', this.options.landPage);
      locationCall(this.options.landPage, this.options.noLandpage);
    }
  }
};

export const openStatus = {
  FAILED: 0,
  SUCCESS: 1,
  UNKNOW: 2
};

export function getSEO(){
  function addCookie(name,value,expires){ 
    var cookieString=name+"="+escape(value); 
    //判断是否设置过期时间 
    if(expires>0){ 
    var date=new Date(); 
    date.setTime(date.getTime()+expires*60000); 
    cookieString=cookieString+"; expires="+date.toGMTString(); 
    } 
    document.cookie=cookieString; 
  } 
  function getCookie(name){ 
    var strCookie=document.cookie; 
    var arrCookie=strCookie.split("; "); 
    for(var i=0;i < arrCookie.length;i++){ 
      var arr=arrCookie[i].split("="); 
      if(arr[0]==name)return arr[1]; 
    } 
    return ""; 
  }
  let cookieValue=getCookie(COOKIE_VALUE);
  if(cookieValue){
    return cookieValue;
  }
  const SEOLinks={
    "sm.cn":"sm",//神马搜索
    "baidu.com":"baidu",//百度搜索
    "hao123.com":"hao123",//好132
    "so.com":"so",//360搜索
    "sogou.com":"sogou",//搜狗
    "bing.com":"bing",//必应
    "google.com":"google",//谷歌
    "yahoo.com":"yahoo",//雅虎
    "haosou.com":"haosou",//好搜
    "douban.com":"douban",//豆瓣
    "zhihu.com":"zhihu",//知乎
    "toutiao":"toutiao",//头条
    "share_dest":"yy_share",//孕育分享
    "wap_app":"wap_app",//自然点击
  };
  const refer=document.referrer;
  let referResult;
  if(window.location.href.indexOf('share_dest')!=-1){
    referResult = SEOLinks.share_dest;
  }else if(!refer){
    referResult = SEOLinks.wap_app;
  }else{
    let seoName="";
    for(let name in SEOLinks){
      if(refer.indexOf(name)!=-1){
        seoName=name;
        break;
      }
    };
    let seoResult=seoName?SEOLinks[seoName]:'';
    referResult = seoResult;
  };
  addCookie(COOKIE_VALUE,referResult,5)
  return referResult;
}
export function getUrlInfo(){
  function getUrlPara(url){
    var index = url.indexOf("?");
    var newurl = url.substring(index + 1);
    var infos = newurl.split("&");
    var para = {};
    infos.map(item => {
      var info = item.split("=");
      para[info[0]] = info[1];
    })
    return para;
  }
  const url=window.location.href;
  let result={
    url,
    pid:getUrlPara(url).id
  };
  // 组件页面重新处理url
  const templateUrls=[
    "babytree.citv.cn/topic/module/wap2/page",
    "bsp.babytree.com/topic/module/wap2/page",
    "bsp.laodaomama.com/topic/module/wap2/page",
    "bsp.babytree-group.com/topic/module/wap2/page",
    "babytree.citv.cn/mp/topic/module/wap2/page",
  ]
  for(let i=0;i<templateUrls.length;i++){
    if(url.indexOf(templateUrls[i])!=-1){
      result.url=`bsp.babytree.com/topic/module/wap2/page/${getUrlPara(url).id}`
    }
  };
  //处理页面id(普通文章:content_id,早教文章:article_id,帖子:“/topic_”后面的数字)
  if(url.indexOf('h5-fe-article.babytree.com/h5_fe_article/html/article/index')!=-1){
    result.pid=getUrlPara(url).content_id;
  }else if(url.indexOf('h5-fe-article.babytree.com/h5_fe_article/html/earlyeducation/detail')!=-1){
    result.pid=getUrlPara(url).article_id;
  }else if(url.indexOf('m.babytree.com/community/yuer/topic_')!=-1){
    let urlSplits=url.split('?')[0].split('?')[0].split('/');
    for(let i=0;i<urlSplits.length;i++){
      if(urlSplits[i].indexOf('topic_')!=-1){
        result.pid=urlSplits[i].split('.html')[0].split('topic_')[1];
        break;
      }
    }
  }
  return result;
}