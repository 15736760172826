import { loadScript, getLibOrigin } from '@bbtfe/utils';

export function lazyWTA() {
  if (window.WTA) {
    return Promise.resolve(window.WTA);
  } else {
    return loadScript(
      `${getLibOrigin()}/h5_fe_lib/release/w2a/current/index.min.js?${
        process.env.VERSION
      }`
    ).then(() => {
      return window.WTA;
    });
  }
}

export function lazyTracking() {
  if (window.bbtTracking) {
    return Promise.resolve(window.bbtTracking);
  } else {
    return loadScript(
      `${getLibOrigin()}/h5_fe_lib/release/track/current/index.min.js?${
        process.env.VERSION
      }`
    ).then(() => {
      return window.bbtTracking;
    });
  }
}

export function lazyWX() {
  if (window.wx) {
    return Promise.resolve(window.wx);
  } else {
    return loadScript('//res.wx.qq.com/open/js/jweixin-1.6.0.js').then(() => {
      return window.wx;
    });
  }
}
